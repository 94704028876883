import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import SubpageTopimage from "../components/SubpageTopimage"
import JobsList from "../components/Jobs"

const Title = styled.h1``

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  padding: 1rem;
  ${mq.tablet} {
    margin-right: 1rem;
    h1 {
      text-align: left;
      border-bottom: none;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const PageTemplate = ({ data }) => {
  const jobtype = data?.wordpressPage?.acf?.jobtype?.value || null

  let jobposting = jobtype
  if (jobposting) {
    jobposting = "true"
  } else jobposting = "false"

  return (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
        date={data.wordpressPage.date}
        jobposting={jobposting}
        jobtype={jobtype}
        modified={data.wordpressPage.modified}
        keywords={[
          `Hauenstein`,
          `Estriche`,
          `Heizestriche`,
          `Schwimmende Estriche`,
          `Anhydrit-Estriche`,
          `Sanierung`,
          `Unterbodenausgleich`,
          `Bauwerksabdichtungen`,
        ]}
      />
      <SubpageTopimage
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid
        }
        alt={data.wordpressPage.title}
      />
      <Wrapper>
        <Textwrapper>
          <Title>{data.wordpressPage.title}</Title>
          <div
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
          />

          <JobsList />
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      modified
      date

      acf {
        jobtype {
          value
        }
      }

      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
